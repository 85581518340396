import "./App.css";
import Counters from "./components/counters";
import React from "react";
import NavBar from "./components/navbar";

class App extends React.Component {
  state = {
    counters: [],
  };

  handleReset = () => {
    const counters = this.state.counters.map((c) => {
      c.value = 0;
      return c;
    });
    this.setState({ counters });
  };

  handleIncrement = (counter) => {
    const counters = [...this.state.counters];
    const index = counters.indexOf(counter);
    counters[index] = { ...counter };
    counters[index].value++;
    this.setState({ counters });
  };

  handleDecrement = (counter) => {
    const counters = [...this.state.counters];
    const index = counters.indexOf(counter);
    counters[index] = { ...counter };
    counters[index].value--;
    this.setState({ counters });
  };

  handleAdd = () => {
    var min = 1;
    var max = 999999;
    let id = min + Math.random() * (max - min);
    let idFinal = Math.round(id);
    this.setState((previousState) => {
      return {
        counters: [...previousState.counters, { id: idFinal, value: "1" }],
      };
    });
  };

  handleDeleteAll = () => {
    this.setState((previousState) => {
      return {
        counters: [],
      };
    });
  };

  handleDelete = (counterId) => {
    const counters = this.state.counters.filter((c) => c.id !== counterId);
    this.setState({ counters });
  };
  render() {
    return (
      <React.Fragment>
        <NavBar
          totalCounters={this.state.counters.filter((c) => c.value > 0).length}
        />
        <main className="container">
          <Counters
            onDeleteAll={this.handleDeleteAll}
            onAdd={this.handleAdd}
            counters={this.state.counters}
            onDecrement={this.handleDecrement}
            onReset={this.handleReset}
            onIncrement={this.handleIncrement}
            onDelete={this.handleDelete}
          />
        </main>
      </React.Fragment>
    );
  }
}
export default App;
