import React, { Component } from 'react';
import Counter from './counter';

class Counters extends React.Component {
    render() {
        return (<div>
            <button onClick={this.props.onAdd} className="btn btn-warning m-2">Add</button>
            <button style={{"opacity": this.getDeleteAllButtonShow()}} onClick={this.props.onReset} className="btn btn-primary m-2">Reset</button>
            <button style={{"opacity": this.getDeleteAllButtonShow()}} onClick={this.props.onDeleteAll} className="btn btn-danger m2">Delete All</button>
            {this.props.counters.map(counter => 
            <Counter 
            key={counter.id} 
            onDelete={this.props.onDelete}
            onIncrement={this.props.onIncrement}
            onDecrement={this.props.onDecrement}
            counter={counter}
            />)}
        </div>);
    }
    
    getDeleteAllButtonShow() {
        let show = 0;
        return show += this.props.counters.length === 0 ? 0 : 1;
    };
    
}
 
export default Counters;