import React, { Component } from 'react';

class Counter extends React.Component {
    render() { 
        return <div>
                <span className={this.getBadgeClasses()}>{this.formatCount()}
            </span>
            <button disabled={this.getDisabledButton()} onClick={() => this.props.onDecrement(this.props.counter)} 
                className="btn btn-secondary m-2">
                    -
                    </button>
                <button onClick={() => this.props.onIncrement(this.props.counter)} 
                className="btn btn-secondary m-2">
                    +
                    </button>
                <button onClick={() => this.props.onDelete(this.props.counter.id)} 
                className="btn btn-danger m-2">
                    Delete
                </button>
            </div>;
    };

    getBadgeClasses() {
        let classes = "badge m-2 bg-";
        classes += this.props.counter.value === 0 ? "warning" : "primary";
        return classes;
    };

    getDisabledButton() {
        const count = this.props.counter.value;
        return count === 0 ? true : false;
    };

    formatCount() {
        const count = this.props.counter.value;
        return count === 0 ? 'zero' : count;
    };
}
 
export default Counter;